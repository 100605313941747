import {initializeApp} from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyBqF5wWC6k3QmlQ3IrBVZ7q-LtOgy2edjI",
    authDomain: "atlanta-iditarod.firebaseapp.com",
    databaseURL: "https://atlanta-iditarod.firebaseio.com",
    projectId: "atlanta-iditarod",
    storageBucket: "atlanta-iditarod.appspot.com",
    messagingSenderId: "773431645353",
    appId: "1:773431645353:web:a0c4375502d3c9f6cc85b8"
  };

const app = initializeApp(firebaseConfig);

export default app;