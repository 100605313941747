export default `Brian O'Connor,Edward's Snow Den,Mitch Seavey,30,Jessie Holmes,7,Mats Pettersson,11,Sebastien Dos Santos Borges,44,4:28:00 PM
Douglas Borenstein,Runaway Sled,Dallas Seavey,20,Paige Drobny,5,Mats Pettersson,11,Bridgett Watkins,36,11:11:00 AM
Elizabeth Otwell ,Do Not Eat the Yellow Snow,Dallas Seavey,20,Ryan Redington,17,Hugh Neff,50,Joe Taylor,22,2:17:00 AM
Dassa,Musher me crazy,Aaron Burmeister,23,Mille Porsild,33,Apayauq Reitan,47,Bridgett Watkins,36,2:18:00 AM
Chris Cobb,Raise The Woof!,Mitch Seavey,30,Richie Diehl,18,Martin Buser,38,Joe Taylor,22,4:29:00 AM
Kim Morris,Ruff racers,Mitch Seavey,30,Matt Hall,31,Hanna Lyrek,13,KattiJo Deeter,19,3:42:00 AM
Jane Guthrie,HuskyPanda,Dallas Seavey,20,Matt Hall,31,Mats Pettersson,11,KattiJo Deeter,19,2:00:00 AM
Handly Cameron,Apocalypse Sled,Brent Sass,27,Paige Drobny,5,Kristy Berington,24,Bridgett Watkins,36,9:32:00 AM
John Mello,I'ma letta dog be a dog,Dallas Seavey,20,Matt Hall,31,Joshua McNeal,45,Amanda Otto,32,4:17:00 AM
Shaun Bank,The year of the Dawg,Aaron Burmeister,23,Mille Porsild,33,Kristy Berington,24,Joe Taylor,22,8:13:00 AM
Craig Marmon,Tail Wagon,Joar Leifseth Ulsom,12,Paige Drobny,5,Aaron Peck,21,Joe Taylor,22,2:17:00 AM
Adam Sheinkopf,Snoop dogg sleddin ,Joar Leifseth Ulsom,12,Paige Drobny,5,Aaron Peck,21,Eric Kelly,14,3:12:00 AM
Barb Reilly,Three Dog Night,Aaron Burmeister,23,Paige Drobny,5,Hugh Neff,50,Gerhardt Thiart,39,11:37:00 PM
Pedro Soto,The Hounds of Fate,Dallas Seavey,20,Matt Hall,31,Joshua McNeal,45,Joe Taylor,22,2:30:00 AM
Jelena Fordella,The Fordella Pawesome,Mitch Seavey,30,Mille Porsild,33,Dan Kaduce,28,Gerhardt Thiart,39,11:04:00 AM
David Mokotoff,Whitey and the 6 Dwarfs,Brent Sass,27,Mille Porsild,33,Anja Radano,26,Amanda Otto,32,6:28:00 AM
Kymberli Roberts,"Chug-A Chug-A, Pup Pup",Mitch Seavey,30,Paige Drobny,5,Hugh Neff,50,Sean Williams,2,4:11:00 AM
Chris Maycock,Covid Kai,Brent Sass,27,Jeff King,3,Mats Pettersson,11,Kailyn Davis,41,7:02:00 AM
Vy Nguyen,Idita Bad Bad Thing,Dallas Seavey,20,Jeff Deeter,34,Hugh Neff,50,Bridgett Watkins,36,12:30:00 PM
Jennifer Nichols,"Blue Eyes, Full Coats, Can't Lose",Aaron Burmeister,23,Matt Hall,31,Aaron Peck,21,Bridgett Watkins,36,5:22:00 PM
Alex Consunji,Destiny's Puppy - Malamuteylicious,Joar Leifseth Ulsom,12,Ramey Smyth,8,Joshua McNeal,45,Matt Paveglio,48,8:01:00 PM
Aaron Berk,March Forth on March Fifth,Brent Sass,27,Matt Hall,31,Yuka Honda,49,Joe Taylor,22,9:45:00 PM
Tarin Levinson,Oops!... I ditarod again,Brent Sass,27,Jessie Holmes,7,Ryne Olson,35,Gerhardt Thiart,39,3:00:00 PM
Nichole Fogle,Mush Puppies,Brent Sass,27,Ryan Redington,17,Martin Buser,38,KattiJo Deeter,19,7:23:00 AM
Alex Qatsha,B'ark de Triomphe,Mitch Seavey,30,Paige Drobny,5,Hanna Lyrek,13,Matt Paveglio,48,6:27:00 AM
Nathan Whitmire,topDAWGS,Joar Leifseth Ulsom,12,Jeff Deeter,34,Lev Shvarts,16,Sean Williams,2,4:44:00 PM
Rick DeMedeiros,"Masked and Vaxxed to Tha Maxx, muthafuckaz!",Aaron Burmeister,23,Travis Beals,42,Ryne Olson,35,Martin Massicotte,6,4:00:00 AM
Shaun McCarthy,Mushmouth or Punkin’ Puss,Brent Sass,27,Travis Beals,42,Chad Stoddard,46,Julie Ahnen,29,10:18:00 PM
Rob Eble,Knot Knome,Dallas Seavey,20,Ramey Smyth,8,Aaron Peck,21,KattiJo Deeter,19,10:30:00 AM
Matt Payne,Iditarwordle,Brent Sass,27,Ramey Smyth,8,Anna Berington,9,Bridgett Watkins,36,7:37:00 AM
Michael McLaughlin,Yellow Snow Machine,Joar Leifseth Ulsom,12,Mille Porsild,33,Deke Naaktgeboren,25,Sean Williams,2,3:17:00 AM
Carrie Flaspohler,Baha Men's Revenge: Locking the Gate,Brent Sass,27,Matt Hall,31,Ryne Olson,35,Julie Ahnen,29,5:16:00 AM
Gil Elliot and Suzanne Ito,Team Fuzzers,Mitch Seavey,30,Jessie Holmes,7,Ryne Olson,35,Julie Ahnen,29,3:14:00 AM
Hamilton Evans,Time Is A Flat Winner's Circle,Mitch Seavey,30,Paige Drobny,5,Aaron Peck,21,Julie Ahnen,29,9:35:00 AM
Brent Carney,Captain Furpants,Mitch Seavey,30,Jeff Deeter,34,Riley Dyche,15,Sean Williams,2,7:00:00 AM
Suz Scarce,Year of the Dawgs,Joar Leifseth Ulsom,12,Richie Diehl,18,Mats Pettersson,11,Kailyn Davis,41,5:00:00 AM
Beth Fore,Squirrel Girl,Mitch Seavey,30,Jessie Holmes,7,Riley Dyche,15,Joe Taylor,22,7:12:00 AM
Chad Orwig,The Ice Pack,Brent Sass,27,Mille Porsild,33,Mats Pettersson,11,Julie Ahnen,29,3:12:00 AM
Mark Fore,The Kessel Runners,Dallas Seavey,20,Peter Kaiser,37,Aaron Peck,21,Julie Ahnen,29,10:00:00 AM
Todd Jackson,Don’t Moose with Me,Mitch Seavey,30,Matt Hall,31,Matthew Failor,10,Joe Taylor,22,4:57:00 AM
Stephen Dixon,The Three Barky Problem,Brent Sass,27,Ramey Smyth,8,Kristy Berington,24,Kailyn Davis,41,6:15:00 AM
Dani Charles,Are They Bering Strait? ,Dallas Seavey,20,Matt Hall,31,Lev Shvarts,16,Matt Paveglio,48,3:21:00 PM
Jim Fancher,Dogg Father,Mitch Seavey,30,Matt Hall,31,Aaron Peck,21,Kailyn Davis,41,7:21:00 AM
Nicole Schaeffer,When I Dipth You Dipth We Dipth,Dallas Seavey,20,Jeff Deeter,34,Aaron Peck,21,Amanda Otto,32,5:04:00 AM
Mara Barack,"Doggone It, People Like Me!",Mitch Seavey,30,Paige Drobny,5,Mats Pettersson,11,Matt Paveglio,48,3:18:00 PM
Robin Orwig,When in Nome,Dallas Seavey,20,Travis Beals,42,Aaron Peck,21,Julie Ahnen,29,3:30:00 AM
Liz Lounsbury,Imadogimadogimadogimadog,Brent Sass,27,Jessie Holmes,7,Michelle Phillips,4,KattiJo Deeter,19,11:47:00 PM
Matt Loutzenhiser,E.T Go Nome,Aaron Burmeister,23,Mille Porsild,33,Michelle Phillips,4,KattiJo Deeter,19,3:12:00 AM
Ellen Evans,A-B-C-D-E-F...Gee pole!,Mitch Seavey,30,Paige Drobny,5,Deke Naaktgeboren,25,Gerhardt Thiart,39,5:36:00 PM
Amy Atteberry,"Jamaica, we have a dogsled team",Dallas Seavey,20,Matt Hall,31,Martin Buser,38,Gerhardt Thiart,39,7:45:00 PM
Bill Aaron,Is There a Dog?,Brent Sass,27,Jeff Deeter,34,Anja Radano,26,KattiJo Deeter,19,1:31:00 AM
Rebecca Leach,Nowhere To Go But Up,Mitch Seavey,30,Richie Diehl,18,Martin Buser,38,Sebastien Dos Santos Borges,44,5:20:00 AM
Christian Nash,Hamilton Sux,Brent Sass,27,Jeff Deeter,34,Dan Kaduce,28,Joe Taylor,22,11:17:00 AM
Joel Levinson,"Mush it, mush it real good (dundundundundun...)",Dallas Seavey,20,Richie Diehl,18,Matthew Failor,10,Eric Kelly,14,4:58:00 AM
Rich Clum,Who Deyarod,Joar Leifseth Ulsom,12,Jeff King,3,Matthew Failor,10,KattiJo Deeter,19,1:30:00 PM
Laurie Gray,The Cyber-ian Huskies,Mitch Seavey,30,Matt Hall,31,Martin Buser,38,Matt Paveglio,48,2:47:00 AM
Michelle Page-Rivera,Ruff'nTumbler,Aaron Burmeister,23,Ryan Redington,17,Dan Kaduce,28,Sebastien Dos Santos Borges,44,12:30:00 AM
Melissa DeMedeiros,Boosted & Ready To Run,Joar Leifseth Ulsom,12,Travis Beals,42,Anja Radano,26,Bridgett Watkins,36,2:30:00 AM
Laura Bolsen,Husky-varna,Joar Leifseth Ulsom,12,Paige Drobny,5,Matthew Failor,10,Sebastien Dos Santos Borges,44,5:11:00 AM
Ben Samuel,"Angry Dogs, And I Mean Really Angry",Aaron Burmeister,23,Jessie Holmes,7,Lev Shvarts,16,Kailyn Davis,41,2:00:00 AM
Todd Bolsen,All Roads Go Through Dallas,Aaron Burmeister,23,Peter Kaiser,37,Apayauq Reitan,47,Matt Paveglio,48,3:13:00 PM
Dan Okray,Someday Came,Dallas Seavey,20,Jeff Deeter,34,Lev Shvarts,16,Gerhardt Thiart,39,6:51:00 AM
Lily Li,Pupsicles,Mitch Seavey,30,Jessie Holmes,7,Anja Radano,26,KattiJo Deeter,19,10:31:00 AM
Brett Harn,Junkyard sled dawgs,Aaron Burmeister,23,Jessie Holmes,7,Hanna Lyrek,13,Amanda Otto,32,3:20:00 PM
Steve Hinman,Snoop,Mitch Seavey,30,Matt Hall,31,Aaron Peck,21,Sean Williams,2,2:00:00 AM
Bethany Riddle,Mush Ado About Nothing,Brent Sass,27,Matt Hall,31,Karin Hendrickson,40,KattiJo Deeter,19,10:22:00 AM
Shah Zafrani,Baha Men,Brent Sass,27,Matt Hall,31,Mats Pettersson,11,KattiJo Deeter,19,6:14:00 PM
Pierre Russell,White Thunder,Aaron Burmeister,23,Travis Beals,42,Hugh Neff,50,Amanda Otto,32,4:00:00 PM
Kim Russell,Blizzard Busters,Mitch Seavey,30,Paige Drobny,5,Chad Stoddard,46,Sebastien Dos Santos Borges,44,3:39:00 AM
Lee Jaffe,Mush Mouth,Mitch Seavey,30,Matt Hall,31,Apayauq Reitan,47,Kailyn Davis,41,10:22:00 AM
Stephanie Strada,The Hungry Huskies,Aaron Burmeister,23,Peter Kaiser,37,Mats Pettersson,11,Joe Taylor,22,4:49:00 AM
Chad Thropp,The Fast and the Flurries,Mitch Seavey,30,Jessie Holmes,7,Hanna Lyrek,13,Matt Paveglio,48,9:47:00 AM
Marcus Bearden,Caribou Chasers,Aaron Burmeister,23,Paige Drobny,5,Mats Pettersson,11,Sebastien Dos Santos Borges,44,2:00:00 AM
Jay Mitchell,View Never Changes,Aaron Burmeister,23,Jessie Holmes,7,Michelle Phillips,4,Bridgett Watkins,36,11:15:00 AM
Rachel Lieberman,MushPuppies,Joar Leifseth Ulsom,12,Paige Drobny,5,Joshua McNeal,45,Martin Massicotte,6,2:22:00 PM
Alan and Angela,Atlanta Heet,Brent Sass,27,Travis Beals,42,Michelle Phillips,4,Sean Williams,2,4:20:00 AM
Rob Collier,Anything is Paw-sible,Dallas Seavey,20,Jeff Deeter,34,Matthew Failor,10,Martin Massicotte,6,11:26:00 AM
Mahdi Albori,Much Fast Huskos,Mitch Seavey,30,Matt Hall,31,Aaron Peck,21,Amanda Otto,32,3:00:00 PM
Carey Johnson,Paws of Glory,Aaron Burmeister,23,Jessie Holmes,7,Ryne Olson,35,Matt Paveglio,48,2:08:00 AM
Andrea Goodman,"There's Snow Place Like Home, Juneau?",Mitch Seavey,30,Mille Porsild,33,Anja Radano,26,Martin Massicotte,6,10:19:00 PM
Jeremy Lizza,Teleracers,Brent Sass,27,Richie Diehl,18,Aaron Peck,21,Amanda Otto,32,4:20:00 AM
Brian Sobel,Captain Chris's Reusable Empanada Box Chimes,Joar Leifseth Ulsom,12,Ramey Smyth,8,Chad Stoddard,46,Eric Kelly,14,2:30:00 AM
Jarrod Carpenter,Gee-pers Creepers,Aaron Burmeister,23,Matt Hall,31,Anna Berington,9,Amanda Otto,32,7:34:00 AM
Luxi Williams,The Kibble Krew,Brent Sass,27,Matt Hall,31,Aaron Peck,21,KattiJo Deeter,19,10:00:00 AM
Elizabeth Abad,Do Dogs Floss,Mitch Seavey,30,Ramey Smyth,8,Michelle Phillips,4,Matt Paveglio,48,2:22:00 AM
Greg Teitelbaum,Nureongi,Mitch Seavey,30,Mille Porsild,33,Kristy Berington,24,Julie Ahnen,29,3:53:00 AM
Steve Porter,Fat kids are harder to kidnap!,Mitch Seavey,30,Matt Hall,31,Aaron Peck,21,Bridgett Watkins,36,6:10:00 AM
Robert Doan,Texas Mayhem Mushers,Mitch Seavey,30,Matt Hall,31,Joshua McNeal,45,Joe Taylor,22,4:02:00 AM
David Srochi,Oops Idarod it again,Mitch Seavey,30,Jessie Holmes,7,Riley Dyche,15,Bridgett Watkins,36,3:33:00 AM
Cindy Mokotoff ,Walter the Iditacat,Dallas Seavey,20,Travis Beals,42,Michelle Phillips,4,Sean Williams,2,8:12:00 AM
Benjamin Mokotoff,The Fifth Wallfacer,Mitch Seavey,30,Matt Hall,31,Lev Shvarts,16,Gerhardt Thiart,39,11:27:00 AM
Jules Borghardt,Mushing Masters,Dallas Seavey,20,Travis Beals,42,Dan Kaduce,28,KattiJo Deeter,19,5:35:00 AM
Stephen Sroka,Here For The Halibut,Brent Sass,27,Mille Porsild,33,Deke Naaktgeboren,25,KattiJo Deeter,19,7:11:00 AM
Alex Kakouris,Mush Love,Mitch Seavey,30,Richie Diehl,18,Aaron Peck,21,KattiJo Deeter,19,2:42:00 PM
Justin Seides,Portabella Mushers,Dallas Seavey,20,Richie Diehl,18,Aaron Peck,21,Amanda Otto,32,5:05:00 AM
Joanne Mello,Bark of Glory,Brent Sass,27,Peter Kaiser,37,Ryne Olson,35,Matt Paveglio,48,12:45:00 AM
Esther Vayman,Pressed Dog,Dallas Seavey,20,Jeff Deeter,34,Ryne Olson,35,Bridgett Watkins,36,5:01:00 AM
Christopher Lieberman,Iron Will But Without Kevin Spacey,Dallas Seavey,20,Matt Hall,31,Joshua McNeal,45,Amanda Otto,32,11:15:00 PM
Paige Sento,Special Jake,Brent Sass,27,Matt Hall,31,Anja Radano,26,Joe Taylor,22,11:04:00 AM
Paul Schur,Snowbiscuit,Mitch Seavey,30,Jeff Deeter,34,Aaron Peck,21,Matt Paveglio,48,6:46:00 AM
Mike Marshall,Biscuit Buddies ,Aaron Burmeister,23,Mille Porsild,33,Apayauq Reitan,47,Kailyn Davis,41,7:27:00 PM
Laura Beystehner,LBs Pups,Brent Sass,27,Richie Diehl,18,Joshua McNeal,45,Sean Williams,2,11:22:00 PM
Barry Brostoff,Casey for the win,Dallas Seavey,20,Mille Porsild,33,Yuka Honda,49,Sean Williams,2,8:07:00 PM
Michael Bernstein,Yellow snow may look tasty…,Brent Sass,27,Ramey Smyth,8,Anna Berington,9,Sebastien Dos Santos Borges,44,7:11:00 AM
Jason Franklin,"Oh my God Becky, look at her Pup",Aaron Burmeister,23,Peter Kaiser,37,Kristy Berington,24,Joe Taylor,22,8:08:00 AM
Ben Alfaro,Juneau who I am?,Dallas Seavey,20,Jeff Deeter,34,Hanna Lyrek,13,Bridgett Watkins,36,11:22:00 PM`;