export default `Pedro Soto,VeryDryBiscuits,Ryan Redington,21,Paige Drobny,38,Jessica Klejka,8,Lara Kittelson,36,2:45:00 AM
Chad Thropp,Sled Zeppelin I,Jessie Holmes,9,Matthew Failor,31,Mats Pettersson,5,Josi Thyr,22,4:42:00 AM
Chris Cobb,It's the leashed I could do,Peter Kaiser,26,Nicolas Petit,17,Jason Mackey,19,Sean Williams,27,11:21:00 AM
Elizabeth Otwell,Do Not Eat the Yellow Snow,Dallas Seavey,7,Travis Beals,14,Jason Mackey,19,Severin Cathry,30,2:23:00 AM
Craig & Ellen Marmon,Wolves Lower,Ryan Redington,21,Jeff Deeter,18,Mats Pettersson,5,Isaac Teaford,15,11:12:00 PM
Andrea Goodman,Who’s a Good(man) Dog?,Ryan Redington,21,Travis Beals,14,Mats Pettersson,5,Calvin Daugherty,37,5:35:00 PM
Jelena Fordella,Leaps & Bounds,Dallas Seavey,7,Jeff Deeter,18,Mats Pettersson,5,Benjamin Good,33,11:22:00 AM
Laurie Gray,Sled Zeppelin II,Peter Kaiser,26,Paige Drobny,38,Anna Berington,2,Josi Thyr,22,2:47:00 AM
Ken Simpson,They're Taking the Hobbits to Icengard,Aaron Burmeister,29,Jeff Deeter,18,Bailey Vitello,32,Benjamin Good,33,5:12:00 AM
Joanne Mello,With Great Powder Comes Great Responsibility,Dallas Seavey,7,Matthew Failor,31,Amanda Otto,28,Joshua Robbins,11,10:05:00 PM
Vuk Sobel,I do need things from a dog – a hound can help you be found,Matt Hall,16,Hunter Keefe,10,Amanda Otto,28,Anna Hennessy,25,2:30:00 AM
Shaun Bank,Nome Alone,Jessie Holmes,9,Jessie Royer,20,Wally Robinson,39,Benjamin Good,33,5:37:00 AM
Geoff Holland,Mush Mush Keep It Down Now,Matt Hall,16,Nicolas Petit,17,Amanda Otto,28,Severin Cathry,30,4:13:00 AM
Ellen Evans,Ruff Day on the Trail,Peter Kaiser,26,Travis Beals,14,Jason Mackey,19,Connor McMahon,3,9:34:00 PM
Perry Gulledge,Mush It Real Good,Aaron Burmeister,29,Travis Beals,14,Anna Berington,2,Connor McMahon,3,4:33:00 PM
Jim Fancher,Dogg Father,Ryan Redington,21,Travis Beals,14,Anna Berington,2,Joshua Robbins,11,11:00:00 AM
Nathan Whitmire,UGA Mathematicians,Aaron Burmeister,29,Paige Drobny,38,Amanda Otto,28,Connor McMahon,3,4:44:00 AM
Larry Anderson,Did you sniff my butt?,Matt Hall,16,Paige Drobny,38,Bailey Vitello,32,Jeff Reid,35,9:34:00 AM
Rich Coutee,You Mush Whip It,Peter Kaiser,26,Hunter Keefe,10,Jason Mackey,19,Benjamin Good,33,1:37:00 AM
Kim Morris,Paw-sitively Awesome,Jessie Holmes,9,Jessie Royer,20,Jessica Klejka,8,Isaac Teaford,15,12:30:00 PM
Lily Li,The Pupsicles,Dallas Seavey,7,Jessie Royer,20,Jason Mackey,19,Calvin Daugherty,37,8:48:00 AM
Joey Ruse,The Ruff Riders,Dallas Seavey,7,Jeff Deeter,18,Bailey Vitello,32,Gabe Dunham,24,9:30:00 AM
Thomas Stubbs,Mush Ado Aboot Nuffn,Peter Kaiser,26,Matthew Failor,31,Jason Mackey,19,Bryce Mumford,6,4:20:00 AM
Rich Clum,Hairy PAWter,Dallas Seavey,7,Travis Beals,14,Bailey Vitello,32,Jeff Reid,35,8:45:00 AM
Aaron Berk,No Way It's Been a Yearditarod,Ryan Redington,21,Paige Drobny,38,Deke Naaktgeboren,34,Lara Kittelson,36,2:00:00 PM
Tara Montano,Snow Way Home,Jessie Holmes,9,Jessie Royer,20,Anna Berington,2,Josi Thyr,22,11:22:00 AM
Helena Chern,Mushelle Dogmama,Matt Hall,16,Jessie Royer,20,Wally Robinson,39,Jeff Reid,35,2:20:00 AM
Douglas Borenstein,The Doug Pound,Jessie Holmes,9,Jessie Royer,20,Bailey Vitello,32,Gabe Dunham,24,4:11:00 PM
Kelly Tabler,Sleigh Queens,Jessie Holmes,9,Paige Drobny,38,Anna Berington,2,Will Rhodes,4,11:26:00 AM
Justin Tennant,White Fang,Peter Kaiser,26,Paige Drobny,38,Amanda Otto,28,Isaac Teaford,15,8:27:00 AM
Rob Collier,Anything is Paw-sible,Peter Kaiser,26,Matthew Failor,31,Anna Berington,2,Severin Cathry,30,6:13:00 PM
Davis Palmie,Bark Side of the Moon,Dallas Seavey,7,Paige Drobny,38,Anna Berington,2,Jeff Reid,35,4:12:00 AM
Stephanie Smith,The Hungry Huskies,Peter Kaiser,26,Jessie Royer,20,Mats Pettersson,5,Anna Hennessy,25,2:21:00 PM
Matt Payne,Confessions by Musher,Jessie Holmes,9,Matthew Failor,31,Amanda Otto,28,Erin Altemus,23,6:27:00 AM
Brent Carney,Zed's Sled Baby,Dallas Seavey,7,Nicolas Petit,17,Jason Mackey,19,Isaac Teaford,15,12:45:00 PM
Christian Nash,GenuinePerpetualTailwaggers,Dallas Seavey,7,Jessie Royer,20,Jason Mackey,19,Josi Thyr,22,7:32:00 AM
Chad Orwig,Long Live The Guap,Matt Hall,16,Mille Porsild,12,Deke Naaktgeboren,34,Benjamin Good,33,9:15:00 PM
Amy Atteberry,Cool Runnings,Ryan Redington,21,Matthew Failor,31,Wally Robinson,39,Jeff Reid,35,7:15:00 PM
Jeremy Lizza,Dogs Aren't Real,Ryan Redington,21,Jessie Royer,20,Bailey Vitello,32,Lara Kittelson,36,1:00:00 AM
Becky Leach,"Oh My God Becky, Look at That Mush",Dallas Seavey,7,Jessie Royer,20,Deke Naaktgeboren,34,Joshua Robbins,11,7:13:00 AM
Brian O'Connoor,Edward's Snow Den,Matt Hall,16,Jeff Deeter,18,Anna Berington,2,Joshua Robbins,11,2:17:00 PM
Grant Guthrie,Try Mushing In My Shoes,Matt Hall,16,Paige Drobny,38,Jason Mackey,19,Benjamin Good,33,3:30:00 PM
Susan Wood,I did it Rod’s way,Peter Kaiser,26,Travis Beals,14,Jason Mackey,19,Gabe Dunham,24,7:02:00 AM
Dan Okray,Someday Came,Peter Kaiser,26,Jessie Royer,20,Mats Pettersson,5,Lara Kittelson,36,9:08:00 AM
Melissa DeMedeiros,Puppy Love,Jessie Holmes,9,Matthew Failor,31,Bailey Vitello,32,Calvin Daugherty,37,3:12:00 AM
Ashley Vanderpoel,In my Iditarod Era,Jessie Holmes,9,Jessie Royer,20,Bailey Vitello,32,Gabe Dunham,24,3:45:00 AM
John Kane,Lion Musher,Aaron Burmeister,29,Matthew Failor,31,Wally Robinson,39,Jeff Reid,35,8:21:00 AM
Christian Wayne,TOGO-ing the Distance,Dallas Seavey,7,Jeff Deeter,18,Jason Mackey,19,Severin Cathry,30,12:00:00 AM
Rick DeMedeiros,Red Rocket Express,Dallas Seavey,7,Jessie Royer,20,Deke Naaktgeboren,34,Lauro Eklund,13,4:00:00 AM
Ashley Nguyen,I don't even go here,Dallas Seavey,7,Jeff Deeter,18,Jessica Klejka,8,Will Rhodes,4,6:32:00 PM
Hamilton Evans,Alaskan Snow Masters,Ryan Redington,21,Jessie Royer,20,Amanda Otto,28,Severin Cathry,30,7:45:00 AM
Bridget Graf,Hot(idita)rod,Peter Kaiser,26,Jeff Deeter,18,Jason Mackey,19,Will Rhodes,4,1:33:00 PM
Zach Tribble,Tribble in Parad-ice,Matt Hall,16,Jessie Royer,20,Bailey Vitello,32,Lauro Eklund,13,7:13:00 AM
Shaun McCarthy,Barkini Kill,Matt Hall,16,Hunter Keefe,10,Anna Berington,2,Erin Altemus,23,8:35:00 PM
Jessie Kane,Ruff Riders,Jessie Holmes,9,Jessie Royer,20,Amanda Otto,28,Lauro Eklund,13,3:00:00 PM
Rebecca Leege,Brave Ones,Jessie Holmes,9,Jessie Royer,20,Bailey Vitello,32,Lauro Eklund,13,11:36:00 AM
Justin Seides,Hush Your Mush,Jessie Holmes,9,Travis Beals,14,Mats Pettersson,5,Gabe Dunham,24,3:23:00 AM
Todd Jackson,Benjamin's Bruisers,Dallas Seavey,7,Hunter Keefe,10,Jessica Klejka,8,Bryce Mumford,6,2:10:00 AM
Handly Cameron,Furry Flurry Express,Matt Hall,16,Matthew Failor,31,Jessica Klejka,8,Anna Hennessy,25,8:13:00 AM
Mahdi Albori,Snowbark Commandos,Aaron Burmeister,29,Jessie Royer,20,Anna Berington,2,Bryce Mumford,6,12:00:00 AM
Mara Barack,Era Tour,Jessie Holmes,9,Travis Beals,14,Wally Robinson,39,Isaac Teaford,15,10:17:00 AM
Christopher Maltusch,The Great Bark-scape,Matt Hall,16,Hunter Keefe,10,Anna Berington,2,Erin Altemus,23,12:43:00 PM
Shelby Benko,Mush Mush Baby,Jessie Holmes,9,Jessie Royer,20,Deke Naaktgeboren,34,Jeff Reid,35,1:17:00 PM
Adam Sheinkopf,Freezing Pupsicles,Peter Kaiser,26,Jeff Deeter,18,Amanda Otto,28,Erin Altemus,23,3:12:00 AM
Robin Orwig,Mush it Real Good,Aaron Burmeister,29,Jessie Royer,20,Anna Berington,2,Calvin Daugherty,37,4:20:00 PM
Sam Stubbs,Lyndon B. Barkson,Peter Kaiser,26,Paige Drobny,38,Bailey Vitello,32,Benjamin Good,33,7:30:00 AM
Dave Kuehling,Fetch Force 5000,Dallas Seavey,7,Jessie Royer,20,Jason Mackey,19,Anna Hennessy,25,6:27:00 AM
Joel levinson,"Oops, Iditarod again",Peter Kaiser,26,Jessie Royer,20,Amanda Otto,28,Lauro Eklund,13,6:22:00 AM
Jane Guthrie,Zero Bark Thirty,Matt Hall,16,Jessie Royer,20,Amanda Otto,28,Severin Cathry,30,5:20:00 AM
Laura Bolsen,Chilly Chihuahuas,Dallas Seavey,7,Jessie Royer,20,Amanda Otto,28,Joshua Robbins,11,5:48:00 AM
Alex Qatsha,Dog Day Afternoon,Jessie Holmes,9,Paige Drobny,38,Anna Berington,2,Lauro Eklund,13,2:36:00 AM
Bill Aaron,Talk to the Paw,Dallas Seavey,7,Hunter Keefe,10,Jason Mackey,19,Benjamin Good,33,10:15:00 PM
Barbara Reilly,Barbie’s Dream Team,Jessie Holmes,9,Travis Beals,14,Bailey Vitello,32,Jeff Reid,35,7:55:00 AM
Gil Elliot and Suzanne Ito,The ChillyDogs,Aaron Burmeister,29,Jessie Royer,20,Anna Berington,2,Lara Kittelson,36,12:13:00 PM
Mark Fore,Slush Puppies,Dallas Seavey,7,Hunter Keefe,10,Amanda Otto,28,Connor McMahon,3,11:30:00 AM
Michael McLaughlin,Big Woof Energy,Matt Hall,16,Nicolas Petit,17,Deke Naaktgeboren,34,Connor McMahon,3,3:04:00 AM
Ronak Pattani,Feeling Mushy,Ryan Redington,21,Jeff Deeter,18,Wally Robinson,39,Erin Altemus,23,12:00:00 PM
Alex Consunji,"Change Route Required, Anvik & Shageluk Moose are Out of Scope",Dallas Seavey,7,Travis Beals,14,Bailey Vitello,32,Connor McMahon,3,11:11:00 AM
Jarrod Carpenter,DALL-E Dogs,Ryan Redington,21,Paige Drobny,38,Deke Naaktgeboren,34,Joshua Robbins,11,5:22:00 AM
Tim Bausman,Moosing Around,Peter Kaiser,26,Matthew Failor,31,Jason Mackey,19,Gabe Dunham,24,11:23:00 AM
Rachel Lieberman,Frost Bites,Ryan Redington,21,Travis Beals,14,Bailey Vitello,32,Josi Thyr,22,2:22:00 PM
Chris Maycock,OpenAI(C),Jessie Holmes,9,Matthew Failor,31,Amanda Otto,28,Jeff Reid,35,7:02:00 AM
Suz Scarce,Mushed Potatoes,Dallas Seavey,7,Matthew Failor,31,Anna Berington,2,Severin Cathry,30,7:15:00 PM
Gil Rogers,"Alaska question, and I expect a prompt answer.",Matt Hall,16,Nicolas Petit,17,Wally Robinson,39,Connor McMahon,3,2:17:00 PM
Alison Lowry,Icy Illusion,Ryan Redington,21,Jessie Royer,20,Amanda Otto,28,Lara Kittelson,36,12:15:00 PM
Ragan Foley,Fur-mula One Racer,Aaron Burmeister,29,Jeff Deeter,18,Wally Robinson,39,Will Rhodes,4,6:36:00 AM
John Oeltmann,Pack of Pugs,Dallas Seavey,7,Mille Porsild,12,Wally Robinson,39,Jeff Reid,35,9:45:00 AM
Kim Russell,Ruffing It All The Way,Ryan Redington,21,Nicolas Petit,17,Bailey Vitello,32,Benjamin Good,33,5:55:00 AM
Pierre Russell,Dog Gone It,Dallas Seavey,7,Nicolas Petit,17,Deke Naaktgeboren,34,Jeff Reid,35,3:00:00 PM
Todd Bolsen,Intergalactic Mush Masters,Aaron Burmeister,29,Jeff Deeter,18,Anna Berington,2,Lara Kittelson,36,9:43:00 AM
Jennifer Nichols,"Blue Eyes, Full Coats, Can't Lose",Matt Hall,16,Paige Drobny,38,Deke Naaktgeboren,34,Sean Williams,27,4:32:00 PM
Stephen Dixon,Ruffenheimer,Jessie Holmes,9,Matthew Failor,31,Mats Pettersson,5,Joshua Robbins,11,6:15:00 AM
John Mello,Team Ultimutt,Matt Hall,16,Mille Porsild,12,Wally Robinson,39,Calvin Daugherty,37,6:47:00 AM
Brian Smith,The Sled Zeppelins,Dallas Seavey,7,Travis Beals,14,Amanda Otto,28,Erin Altemus,23,11:59:00 PM
Paige Sento,Theatrical Penquin,Dallas Seavey,7,Nicolas Petit,17,Jason Mackey,19,Benjamin Good,33,4:18:00 AM
Austin Assavavallop,Fiercely Canine,Dallas Seavey,7,Paige Drobny,38,Mats Pettersson,5,Jeff Reid,35,2:36:00 PM
David Vanderpoel,Stop Trying to Make Fetch Happen,Dallas Seavey,7,Matthew Failor,31,Bailey Vitello,32,Jeff Reid,35,4:20:00 AM
Jake Martin,Martin's Majestic Malamutes,Matt Hall,16,Jeff Deeter,18,Jessica Klejka,8,Benjamin Good,33,5:37:00 AM
Jason Franklin,Woof Tang Clan,Dallas Seavey,7,Jessie Royer,20,Jessica Klejka,8,Lara Kittelson,36,8:08:00 AM
Marty Young,Sled Poets Society,Peter Kaiser,26,Nicolas Petit,17,Jessica Klejka,8,Bryce Mumford,6,5:55:00 AM
John Cox,Husky Mushers,Jessie Holmes,9,Paige Drobny,38,Deke Naaktgeboren,34,Lara Kittelson,36,2:00:00 PM
Jacqueline Newbold,Mush Love Dogs,Dallas Seavey,7,Travis Beals,14,Deke Naaktgeboren,34,Calvin Daugherty,37,8:00:00 AM
Sherry Stenehjem,Icebound Hounds,Ryan Redington,21,Jessie Royer,20,Jessica Klejka,8,Sean Williams,27,11:13:00 PM
Carey Johnson,"I just ran, I ran all night and day",Dallas Seavey,7,Jessie Royer,20,Amanda Otto,28,Isaac Teaford,15,3:12:00 AM
Aashka Patel,Pawsome,Matt Hall,16,Jeff Deeter,18,Wally Robinson,39,Benjamin Good,33,7:20:00 AM
Beth Fore,"Wonderpups, Activate!",Jessie Holmes,9,Jessie Royer,20,Deke Naaktgeboren,34,Erin Altemus,23,6:32:00 AM
Blyden Nchako,Playtime with Cerberus,Matt Hall,16,Jeff Deeter,18,Amanda Otto,28,Bryce Mumford,6,4:00:00 AM
Benjamin Mokotoff,"Whoa, whoa, whoa. There's still plenty of meat on that bone!",Aaron Burmeister,29,Nicolas Petit,17,Deke Naaktgeboren,34,Sean Williams,27,8:46:00 AM
Cindy Mokotoff,"Where We're Going, We Don't Need Roads",Dallas Seavey,7,Mille Porsild,12,Wally Robinson,39,Isaac Teaford,15,8:47:00 PM
Dean Crawford,STAR-LORD,Peter Kaiser,26,Jeff Deeter,18,Jessica Klejka,8,Lara Kittelson,36,7:30:00 AM
Allison Moberg,Just keep mushing,Peter Kaiser,26,Paige Drobny,38,Jason Mackey,19,Anna Hennessy,25,6:43:00 AM
Chazz Hall,Cool Puppy Giggles,Jessie Holmes,9,Matthew Failor,31,Bailey Vitello,32,Sean Williams,27,4:13:00 AM
Charles Schreiner,The Fast and the Furriest,Peter Kaiser,26,Paige Drobny,38,Amanda Otto,28,Jeff Reid,35,4:47:00 AM
Rob Eble,Exploder the Explorer,Peter Kaiser,26,Paige Drobny,38,Amanda Otto,28,Isaac Teaford,15,3:33:00 AM
Matt Matuszewski,Murphy’s Law,Dallas Seavey,7,Hunter Keefe,10,Jason Mackey,19,Connor McMahon,3,10:32:00 PM
Steve Hinman,Max and Ozzie,Jessie Holmes,9,Paige Drobny,38,Bailey Vitello,32,Lara Kittelson,36,3:55:00 AM
Matt Loutzenhiser,Travelin’ Dog,Ryan Redington,21,Hunter Keefe,10,Wally Robinson,39,Connor McMahon,3,4:05:00 AM
Carl Newton,This will be my year,Dallas Seavey,7,Paige Drobny,38,Jason Mackey,19,Jeff Reid,35,2:45:00 AM
Liz Lounsbury,Imadogimadogimadogimadog,Jessie Holmes,9,Jessie Royer,20,Bailey Vitello,32,Benjamin Good,33,4:21:00 AM
Sarah Duffy,Mush Ado About Huskies,Jessie Holmes,9,Paige Drobny,38,Bailey Vitello,32,Josi Thyr,22,5:05:00 AM
David Mokotoff,Slip Sliding Away,Dallas Seavey,7,Jessie Royer,20,Amanda Otto,28,Jeff Reid,35,11:23:00 AM
Kymberli,"Ah, Paws It!",Dallas Seavey,7,Jessie Royer,20,Amanda Otto,28,Erin Altemus,23,4:11:00 AM
Elizabeth Abad,Woof Gang Luck,Peter Kaiser,26,Jessie Royer,20,Amanda Otto,28,Jeff Reid,35,1:17:00 AM
Christopher Lieberman,The Diamond Dogs,Ryan Redington,21,Paige Drobny,38,Anna Berington,2,Lara Kittelson,36,3:42:00 AM
Kirsten Hartley,Paw-ty Animals,Jessie Holmes,9,Matthew Failor,31,Bailey Vitello,32,Jeff Reid,35,11:28:00 PM
Ben Alfaro,No Sleep Till Nome,Peter Kaiser,26,Paige Drobny,38,Anna Berington,2,Lara Kittelson,36,9:48:00 PM
Carrie Flaspohler,Hungry Like the Woof,Peter Kaiser,26,Jessie Royer,20,Jason Mackey,19,Josi Thyr,22,5:43:00 AM
Mary King,Crowned Canines,Jessie Holmes,9,Travis Beals,14,Bailey Vitello,32,Lauro Eklund,13,4:12:00 PM
Laura Beystehner,Ellie's Lineage,Jessie Holmes,9,Jessie Royer,20,Anna Berington,2,Lara Kittelson,36,9:42:00 PM
Gabby John,Zoomies,Matt Hall,16,Jeff Deeter,18,Bailey Vitello,32,Josi Thyr,22,3:30:00 PM
Alan Shearer,Sled Zepplin III,Peter Kaiser,26,Paige Drobny,38,Deke Naaktgeboren,34,Connor McMahon,3,4:26:00 AM
Robert Doan,Texas Mayhem Mushers,Ryan Redington,21,Paige Drobny,38,Jason Mackey,19,Connor McMahon,3,4:21:00 AM
Doug White,The Pupsicles,Jessie Holmes,9,Matthew Failor,31,Bailey Vitello,32,Jeff Reid,35,3:30:00 AM`;