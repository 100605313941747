import app from './firebaseConfig';
import year from './year';
import { collection, getFirestore, doc, onSnapshot, getDoc } from 'firebase/firestore';

const db = getFirestore(app);
const raw = collection(db, 'raw');
const standings = doc(raw, `${year}-standings`)
export function subscribeToStandings(successCallback, failCallback) {
    return onSnapshot(standings, {
            next : (snapshot) => successCallback(snapshot.data()?.data ?? []),
            error: failCallback
        });
}

/**
 * 
 * @param {string} checkpoint 
 * @return {DocumentSnapshot} the data
 */
export function getCheckpointSnapshotDocument(checkpoint) {
    const ref = doc(standings, 'checkpoint-snapshots', checkpoint);
    return getDoc(ref);
}