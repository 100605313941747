import * as parseCallback from 'csv-parse';
import {promisify} from 'util';
import raw from './raw';
import year from '../year';
const parse = promisify(parseCallback);

const rawSelections = raw[year];

const parse3Teams = ([name, team, mush1, bib1, mush2, bib2, mush3, bib3, time]) => ({ name, team, bibs: [bib1, bib2, bib3], time, mushFromSelections : [ mush1, mush2, mush3 ]})
const parse4Teams = ([name, team, mush1, bib1, mush2, bib2, mush3, bib3, mush4, bib4, time]) => ({ name, team, bibs: [bib1, bib2, bib3, bib4], time, mushFromSelections : [ mush1, mush2, mush3, mush4 ]})
export default parse(rawSelections)
    .then(arrayOfArrays => arrayOfArrays.map(arr => arr.length === 9 ? parse3Teams(arr) : parse4Teams(arr)));