import React from 'react';
import { Badge } from 'antd';
import { green, red } from '@ant-design/colors';

const improvingProps = {
  style: { background: green[1] }
};

const worseningProps = {
  style: { background: red[1] }
};

export const sortNumeric = (prop) => (a,b) => a[prop] - b[prop];
export const sortAlpha = (prop) => (a,b) => a[prop].localeCompare(b[prop]);
export function buildImprovingRender({sorter, lowerIsBetter}) {
    return (text, record) => {
      const old = record?.oldData;
      if(old == null){
        return text;
      }
      const val = sorter(record, old);
      if(val === 0) {
        return text;
      }
      const improving = (val < 0) === lowerIsBetter
      const props = improving ? improvingProps : worseningProps;
      const backgroundColor = improving ? green.primary : red.primary;
      return {
        children : <Badge count={val} style={{ backgroundColor }} offset={[7,-7]}>{text}</Badge>,
        props,
      };
    }
  }