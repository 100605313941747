import React, { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Badge, Table } from 'antd';
import { useLeaderboard } from '../context';
import { buildImprovingRender, sortAlpha, sortNumeric } from '../columnUtils';
import { green, red } from '@ant-design/colors';

const scoreRenderer = buildImprovingRender({ sorter: sortNumeric('score'), lowerIsBetter: true });

const columns = [
    {
        title: 'Rank',
        dataIndex: 'rank',
        key: 'rank',
        sorter: sortNumeric('rank'),
        defaultSortOrder : 'ascend',
        render : buildImprovingRender({ sorter : sortNumeric('rank'), lowerIsBetter: true})
    },
    {
        title: 'Sponsor',
        dataIndex: 'name',
        key: 'name',
        sorter: sortAlpha('name'),
    },
    {
        title: 'Team Name',
        dataIndex: 'team',
        key: 'team',
        sorter: sortAlpha('team'),
        render: (text, {allScratched}) => {
            return <span style={allScratched ? { textDecoration: 'line-through'} : undefined}>{text}</span>
            
        },
    },
    {
        title: 'Score',
        dataIndex: 'score',
        key: 'score',
        sorter: sortNumeric('score'),
        render : (text, record) => record.allScratched ? '-' : scoreRenderer(text, record)
    }
]

const expandColumns = [
    {
        title: 'Musher',
        dataIndex: 'musher',
        key: 'musher',
        render : (text, {bib, scratched}) => <Link to={`/standings#${bib}`} style={scratched ? { textDecoration: 'line-through'} : undefined}>{text}</Link>
    },
    {
        title : 'Position',
        dataIndex: 'pos',
        key: 'pos',
        render: buildImprovingRender({ sorter: sortNumeric('pos'), lowerIsBetter: true}),
    }
];

function Leaderboard({ leaderboardOverride}) {
    const {leaderboard} = useLeaderboard();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(!leaderboardOverride && !leaderboard);
    }, [leaderboard, leaderboardOverride])

    return <Table
        dataSource={leaderboardOverride ?? leaderboard}
        columns={columns}
        pagination={false}
        loading={loading}
        rowKey='name'
        onRow={({team}) => ({ id: team.replace(/\W/g, '-').toLowerCase() })}
        locale={{emptyText: loading ? 'Loading' : 'Waiting On Standings from Iditarod.com'}}
        expandedRowRender={({mushers}) => (
            <Table
                dataSource={mushers}
                columns={expandColumns}
                pagination={false}
                showHeader={false}
                summary={mushers => {
                    const score = mushers.map(({pos}) => pos).reduce((a,b) => a+b, 0);
                    const oldScore = mushers.map((musher) => musher?.oldData?.pos)
                        .reduce((a,b) => {
                            if(a == null || b == null) return null;
                            return a + b;
                        }, 0)

                    if(oldScore == null || oldScore === score) {
                        return (
                            <tr>
                                <th>Score</th>
                                <th>{score}</th>
                            </tr>
                        );
                    }
                    const diff = score - oldScore;
                    const badgeBackground = diff < 0 ? green.primary : red.primary;
                    const cellBackground = diff < 0 ? green[1] : red[1];
                    const scoreElement = <th style={{backgroundColor: cellBackground}}><Badge count={diff} style={{ backgroundColor: badgeBackground }} offset={[7,-7]}>{score}</Badge></th>;

                    return (
                        <tr>
                            <th>Score</th>
                            {scoreElement}
                        </tr>
                    )
                }}
            />
        )}
    />
}

export default Leaderboard;