import React from 'react';
import { useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import {Link} from 'react-router-dom'
import aicLogo from '../images/AIC-logo.svg';

function Header() {
    const location = useLocation();
    const selectedKeys = location.pathname === '/standings' ? ['standings'] : ['leaderboard'];
    return (
        <div className="header">
            <Link to="/"><img className="logo" alt="AIC" src={aicLogo} /></Link>
            <h3>Atlanta Iditarod Challenge</h3>
            <Menu mode='horizontal' selectedKeys={selectedKeys}>
                <Menu.Item key='leaderboard'><Link to="/" >Leaderboard</Link></Menu.Item>
                <Menu.Item key='standings'><Link to="/standings">Standings</Link></Menu.Item>
            </Menu>
        </div>
    );
}

export default Header;