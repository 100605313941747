import raw2022 from "./raw2022";
import raw2023 from "./raw2023";
import raw2024 from './raw2024';

const raw2020 = `Chad Orwig,Asleep on the Skids,Mitch Seavey,27,Tom Frode Johansen,39,Linwood Fiedler,8,6:53:00 PM
Hamilton Evans,"I'm Unstoppable, Joar Not",Ramey Smyth,5,Fabio Berlusconi,6,Brent Sass,44,5:00:00 AM
Pedro Soto,The Uncanny Canines,Mitch Seavey,27,Damon Ramaker,52,Tom Knolmayer,30,3:00:00 AM
Gil and Suzanne,Team Fuzzers,Aliy Zirkle,23,Riley Dyche,48,Larry Daugherty,45,2:17:00 AM
Chad Thropp,When in Nome,Nicolas Petit,21,Damon Ramaker,52,Tom Knolmayer,30,9:48:00 AM
Chris Cobb,Game of Nomes,Ramey Smyth,5,Damon Ramaker,52,Thomas Waerner,40,2:10:00 AM
Elizabeth Otwell,Do Not Eat the Yellow Snow,Travis Beals,20,Quince Mountain,50,Michelle Phillips,33,2:13:00 AM
MPR,Southern Huskies,Aliy Zirkle,23,Grayson Bruton,51,Michelle Phillips,33,3:35:00 AM
Michael Bernstein,Yellow Snow Cones,Travis Beals,20,Fabio Berlusconi,6,Jessie Holmes,34,6:14:00 PM
Laurie Gray,The Classy Mother Puppers,Aaron Burmeister,25,Grayson Bruton,51,Jessie Holmes,34,1:13:00 PM
Kim Morris,Anything is Paw-sible,Joar Leifseth Ulsom,35,Damon Ramaker,52,Michelle Phillips,33,3:42:00 AM
Douglas Borenstein,Snoop Dougie Dogg,Travis Beals,20,Quince Mountain,50,Aaron Peck,36,6:06:00 AM
Adrian So,Husky Do,Ramey Smyth,5,Deke Naaktgeboren,14,Linwood Fiedler,8,2:48:00 AM
Vy Nguyen,Not Fat Just a Little Husky,Aaron Burmeister,25,Fabio Berlusconi,6,Matthew Failor,29,12:55:00 PM
Rick DeMedeiros,Scooby Cinematic Universe,Travis Beals,20,Riley Dyche,48,Jeff Deeter,24,1:28:00 AM
Craig Marmon,Sultans of Soy,Wade Marrs,7,Quince Mountain,50,Jim Lanier,55,1:12:00 AM
Shaun Bank,Floppy’s Fellows,Peter Kaiser,9,Kaci Murringer,57,Brent Sass,44,4:23:00 AM
Kory Donato,Pup Mania,Joar Leifseth Ulsom,35,Kaci Murringer,57,Alan Eischens,49,10:30:00 AM
Aaron Berk,Nome or Bust!,Travis Beals,20,Gabe Dunham,17,Meredith Mapes,13,5:45:00 PM
Alex Consunji,Yukon Cornelius' Silver and Gold,Nicolas Petit,21,Fabio Berlusconi,6,Lev Shvarts,10,10:24:00 AM
Brian O’Connor,Edward’s Snow Den,Wade Marrs,7,Mille Porsild,32,Laura Neese,11,9:31:00 PM
Jim Fancher,Dogg Father,Nicolas Petit,21,Damon Ramaker,52,Monica Zappa,43,5:45:00 AM
Kim Russell,Yukon Mushers,Joar Leifseth Ulsom,35,Gabe Dunham,17,Karin Hendrickson,15,4:17:00 AM
Pierre Russell,Slippery Slushers,Nicolas Petit,21,Grayson Bruton,51,Jason Campeau,19,1:00:00 AM
David Mokotoff,Whitey's Revenge,Joar Leifseth Ulsom,35,Riley Dyche,48,Thomas Waerner,40,6:28:00 AM
Brett Harn,The Martin Buser stans,Travis Beals,20,Damon Ramaker,52,Meredith Mapes,13,2:48:00 AM
Laura Bolsen,Tail-er Swift,Aliy Zirkle,23,Grayson Bruton,51,Laura Neese,11,3:41:00 AM
Dave Smith,Huffing puffins,Wade Marrs,7,Fabio Berlusconi,6,Jeremy Keller,47,1:38:00 PM
Shaun McCarthy,Mush Respekt,Wade Marrs,7,Deke Naaktgeboren,14,Dennis Kananowicz,53,4:05:00 AM
Tom Verkooijen,Snow-n-Steady,Nicolas Petit,21,Riley Dyche,48,Tom Knolmayer,30,1:12:00 PM
Mark Fore,Northbound and Down,Nicolas Petit,21,Damon Ramaker,52,Jessica Klejka,41,2:30:00 PM
Carl Newton,Should be a Kevin Costner film,Wade Marrs,7,Gabe Dunham,17,Magnus Kaltenborn,38,4:27:00 AM
Greg Teitelbaum,Spuds MacKenzie,Wade Marrs,7,Mille Porsild,32,Robert Redington,28,5:38:00 AM
Steve Hinman,Powder Hounds,Aaron Burmeister,25,Fabio Berlusconi,6,Linwood Fiedler,8,5:00:00 AM
Stacey Voogd,Ivana Win,Travis Beals,20,Gabe Dunham,17,Michelle Phillips,33,4:32:00 PM
Rob Collier,Paws of the Wild,Nicolas Petit,21,Grayson Bruton,51,Jessica Klejka,41,9:37:00 AM
Christian Nash,Pawpin' Off,Mitch Seavey,27,Gabe Dunham,17,Zoya DeNure,42,3:33:00 PM
Handly Cameron,Togo Was Robbed,Wade Marrs,7,Quince Mountain,50,Laura Neese,11,1:42:00 PM
Todd Bolsen,Baby We Were Born to Run,Wade Marrs,7,Gabe Dunham,17,Michelle Phillips,33,5:29:00 AM
Alex Qatsha,That's a Mastiff Husky you got there!,Wade Marrs,7,Fabio Berlusconi,6,Monica Zappa,43,7:26:00 AM
Joanne Mello,The Art of Racing in the Snow,Wade Marrs,7,Damon Ramaker,52,Jessica Klejka,41,3:05:00 AM
Jason Franklin,Goin' Mutts,Aaron Burmeister,25,Damon Ramaker,52,Magnus Kaltenborn,38,8:08:00 PM
Ben Samuel,Extra Agitated Cujo,Nicolas Petit,21,Deke Naaktgeboren,14,Aaron Peck,36,3:45:00 AM
Rob Eble,The Incident,Aliy Zirkle,23,Damon Ramaker,52,Tom Knolmayer,30,12:45:00 AM
Adam Sheinkopf,DoggiePooPoo,Wade Marrs,7,Fabio Berlusconi,6,Linwood Fiedler,8,3:45:00 AM
Michael McLaughlin,Hoofin & Woofin,Ramey Smyth,5,Deke Naaktgeboren,14,Thomas Waerner,40,5:22:00 AM
Beth Fore,Purple Puppy Power,Aliy Zirkle,23,Damon Ramaker,52,Karin Hendrickson,15,7:12:00 AM
Chris Maycock,Quarantine Runners,Nicolas Petit,21,Damon Ramaker,52,Anna Berington,18,6:35:00 AM
Stephen Dixon,#NeverRedLantern,Joar Leifseth Ulsom,35,Grayson Bruton,51,Jessica Klejka,41,11:00:00 AM
Brent Carney,Captain FurPants,Jessie Royer,31,Riley Dyche,48,Richie Diehl,16,5:03:00 AM
Elisa Warren,Tusher Musher,Aliy Zirkle,23,Deke Naaktgeboren,14,Richie Diehl,16,8:43:00 AM
Susan Scarce,Go Dawgs!,Travis Beals,20,Deke Naaktgeboren,14,Jessie Holmes,34,2:43:00 AM
Robert Doan,Texas Mayhem Mushers,Joar Leifseth Ulsom,35,Mille Porsild,32,Martin Buser,26,3:53:00 AM
Todd Jackson,Team RamRod,Travis Beals,20,Deke Naaktgeboren,14,Thomas Waerner,40,4:20:00 AM
Jeremy Lizza,Blizzard Busters,Jessie Royer,31,Quince Mountain,50,Jessica Klejka,41,7:12:00 PM
Melissa DeMedeiros,Sparkle Bark,Wade Marrs,7,Gabe Dunham,17,Alan Eischens,49,1:30:00 AM
John Mello,Placeholder,Aaron Burmeister,25,Mille Porsild,32,Monica Zappa,43,3:17:00 AM
Brian And Mina,I am Roof,Mitch Seavey,27,Damon Ramaker,52,Dennis Kananowicz,53,2:30:00 PM
Nicole Schaeffer,Mush Puppies,Aliy Zirkle,23,Kaci Murringer,57,Michelle Phillips,33,12:36:00 AM
David Srochi,The SroSnow Redemption,Peter Kaiser,9,Quince Mountain,50,Alan Eischens,49,3:14:00 AM
Nichole Fogle,The Snow Offs,Ramey Smyth,5,Tom Frode Johansen,39,Alan Eischens,49,4:19:00 AM
Kymberli Roberts,Pawsome Pioneers,Wade Marrs,7,Grayson Bruton,51,Jessie Holmes,34,1:37:00 AM
Bill Aaron,Make No Mushtake,Aaron Burmeister,25,Tom Frode Johansen,39,Kristy Berington,4,1:39:00 AM
James Clark,The Mushers,Wade Marrs,7,Damon Ramaker,52,Lev Shvarts,10,8:41:00 AM
Carrie Flaspohler,The Mush Factor,Nicolas Petit,21,Damon Ramaker,52,Jason Campeau,19,2:13:00 PM
Bethany Riddle,Pink Bouffants,Aaron Burmeister,25,Gabe Dunham,17,Jessica Klejka,41,9:43:00 AM
Lily Li & Jatin Nanda,The Pupsicles,Ramey Smyth,5,Quince Mountain,50,Ryan Redington,37,3:45:00 PM
Kesha Maze,We Came to Sleigh,Nicolas Petit,21,Sean Underwood,46,Lev Shvarts,10,3:30:00 PM
Mike Marshall,Don’t Doubt Your Ride - Elon Mush,Peter Kaiser,9,Quince Mountain,50,Magnus Kaltenborn,38,11:47:00 AM
Mara Barack,Barackuda,Travis Beals,20,Tom Frode Johansen,39,Kristy Berington,4,1:30:00 PM
Ashley Parker,The PUPinators!,Wade Marrs,7,Tom Frode Johansen,39,Nils Hahn,56,3:13:00 PM
Paige Sento,Gunpowder Trailers,Aaron Burmeister,25,Damon Ramaker,52,Lance Mackey,12,2:00:00 PM
Olga Tadesse,Yokon't Touch This,Wade Marrs,7,Deke Naaktgeboren,14,Magnus Kaltenborn,38,4:30:00 PM
Steve Porter,Fat kids are harder to kidnap,Ramey Smyth,5,Deke Naaktgeboren,14,Martin Buser,26,4:20:00 AM
Barb Reilly,Nordic Girl,Travis Beals,20,Mille Porsild,32,Dennis Kananowicz,53,5:46:00 PM
Barry Brostoff,Salmon Roe,Travis Beals,20,Tom Frode Johansen,39,Kristy Berington,4,6:22:00 AM
Jane Guthrie,Star Barker,Joar Leifseth Ulsom,35,Deke Naaktgeboren,14,Magnus Kaltenborn,38,4:00:00 PM
Grant Guthrie,ChilledPup,Aaron Burmeister,25,Fabio Berlusconi,6,Meredith Mapes,13,8:00:00 PM
Marcus Bearden,Cold Hearted Mushers,Wade Marrs,7,Riley Dyche,48,Ryan Redington,37,11:52:00 PM
Benjamin Mokotoff,Happy Scrappy Hero Pups,Nicolas Petit,21,Quince Mountain,50,Laura Neese,11,4:43:00 AM
Cynthia Mokotoff,Unlimited Candy Beans = Off the Snow Hook,Travis Beals,20,Deke Naaktgeboren,14,Larry Daugherty,45,4:17:00 AM
Ellen Evans,Woof Woof Rescue,Aliy Zirkle,23,Deke Naaktgeboren,14,Anna Berington,18,6:36:00 AM`;

const raw2021 = `Jeremy Lizza,Frozen Tendies,Dallas Seavey,23,Matt Hall,17,Jeremy Traska,5,3:07:00 AM
Vy Nguyen,Sub-Zero Woofers,Paige Drobny,42,Matt Hall,17,Brenda Mackey,15,10:00:00 PM
Chad Orwig,Yukon't Be Serious,Peter Kaiser,3,Martin Buser,34,Will Troshynski,27,4:37:00 AM
Chris Cobb,Mushed Potatoes,Joar Leifseth Ulsom,41,Matt Hall,17,Jeremy Traska,5,2:41:00 AM
Craig Marmon,Wolves of Maul Street,Peter Kaiser,3,Mille Porsild,28,Chad Stoddard,18,1:51:00 AM
David Srochi,Don’t get saucey with me Bernese! ,Dallas Seavey,23,Riley Dyche,16,Sean Underwood,47,12:12:00 AM
Jane Guthrie,Hot Dogs with Cold Slaw,Jessie Royer,24,Kristy Berington,4,Chad Stoddard,18,4:34:00 AM
Kim Morris,Ruff Racers,Nicolas Petit,10,Kristy Berington,4,Will Troshynski,27,3:41:00 AM
Rob Eble,When in Nome,Dallas Seavey,23,Kristy Berington,4,Dakota Schlosser,39,12:45:00 AM
Douglas Borenstein,Classy Mother Puppers,Aliy Zirkle,32,Aaron Peck,2,Christopher Parker,19,6:06:00 AM
Barb Reilly,All Mush No Slush,Paige Drobny,42,Dennis Kananowicz,8,Hal Hanson,12,12:32:00 PM
Rick DeMedeiros,"Who Let the Dogs Out? No, Seriously, Someone Let Out My Dogs and They're Missing. Please, Help Me. This Isn't a Joke.",Dallas Seavey,23,Dennis Kananowicz,8,Joshua McNeal,30,3:00:00 AM
Melissa DeMedeiros,Queen Princess Luna Elestia Cadence Pounce Jaguar,Travis Beals,33,Richie Diehl,6,Dakota Schlosser,39,2:30:00 AM
Michael McLaughlin,Hoofin’ and Woofin’,Nicolas Petit,10,Matt Hall,17,Joshua McNeal,30,3:33:00 PM
David Mokotoff,Whitey's Red Sled in Van Gogh's Painted Snow,Paige Drobny,42,Kristy Berington,4,Joanna Jagow,22,11:23:00 AM
Jim Fancher,Dogg Father,Brent Sass,21,Ryne Olson,14,Joanna Jagow,22,3:30:00 AM
Stacey Voogd,The Zoomies,Nicolas Petit,10,Martin Buser,34,Brenda Mackey,15,10:15:00 AM
Matt Loutzenhiser,Deshkta to Deshkta,Joar Leifseth Ulsom,41,Ryne Olson,14,Sean Underwood,47,3:42:00 AM
Shaun McCarthy,Mush Little Baby Don’t Say A Word,Nicolas Petit,10,Gunnar Johnson,11,Joanna Jagow,22,6:45:00 PM
Dave smith,Hike!Gee!Haw!,Paige Drobny,42,Ryne Olson,14,Dakota Schlosser,39,12:02:00 PM
Laura Bolsen,And They Call IT Puppy Love,Brent Sass,21,Dan Kaduce,40,Chad Stoddard,18,12:48:00 AM
Shaun Bank,Bank’s Barkers,Nicolas Petit,10,Mille Porsild,28,Sean Underwood,47,8:27:00 AM
Brian O’Connor,Edward’s Snow Den,Jessie Royer,24,Jeff Deeter,7,Will Troshynski,27,11:23:00 AM
Grant Guthrie,Diamond Paws,Aliy Zirkle,32,Wade Marrs,25,Sean Underwood,47,3:00:00 PM
Joanne Abrams Mello,Bark of Glory,Peter Kaiser,3,Dennis Kananowicz,8,Susannah Tuminelli,31,6:00:00 AM
Gil Elliot,The Quaranteam,Aliy Zirkle,32,Dan Kaduce,40,Joanna Jagow,22,1:00:00 AM
Susan Scarce,The Penny Laners,Nicolas Petit,10,Dan Kaduce,40,Will Troshynski,27,1:23:00 PM
Rich Clum,Doggone Best Shot,Nicolas Petit,10,Riley Dyche,16,Dakota Schlosser,39,8:32:00 PM
Ben Samuel,A bunch of angry dogs,Travis Beals,33,Kristy Berington,4,Hal Hanson,12,3:00:00 AM
Alex Qatsha,You Look Quite Fetching,Paige Drobny,42,Larry Daugherty,43,Chad Stoddard,18,6:15:00 AM
Alex Consunji,Drool Runnings,Jessie Royer,24,Victoria Hardwick,48,Will Troshynski,27,11:26:00 AM
Aaron Berk,Nome-o-Fomo,Aaron Burmeister,36,Cindy Gallea,46,Christopher Parker,19,8:20:00 AM
Michael Bernstein,"Oh, you ran a marathon...how heavy was the sled?",Aaron Burmeister,36,Matt Hall,17,Chad Stoddard,18,2:46:00 PM
Todd Jackson,The Dogg Pound,Jessie Royer,24,Anna Berington,13,Joanna Jagow,22,3:28:00 AM
Brett Harn,Lassie Come Nome,Aaron Burmeister,36,Rick Casillo,45,Chad Stoddard,18,3:20:00 PM
Stephen Dixon,I Mush Ask You A Question,Travis Beals,33,Dennis Kananowicz,8,Will Troshynski,27,8:32:00 AM
Tarin Levinson,Freeze the Day,Aliy Zirkle,32,Dan Kaduce,40,Joanna Jagow,22,2:00:00 PM
Stephanie Strada,Hungry Huskies,Joar Leifseth Ulsom,41,Matt Hall,17,Joanna Jagow,22,3:45:00 AM
Brian Sobel,He Wolf,Joar Leifseth Ulsom,41,Ryne Olson,14,Joshua McNeal,30,2:30:00 PM
Carey Johnson,Can I get the Rona from my dog?,Nicolas Petit,10,Ramey Smyth,9,Joanna Jagow,22,2:18:00 AM
Jason Franklin,Air Bud for Prez 2024,Travis Beals,33,Matt Hall,17,Chad Stoddard,18,8:08:00 PM
Chris Maycock,Quit Biden time and start running your Ossoff!,Joar Leifseth Ulsom,41,Lev Shvarts,38,Hal Hanson,12,4:45:00 AM
Dassa,Up the Rainy Pass,Aliy Zirkle,32,Ryne Olson,14,Chad Stoddard,18,3:30:00 AM
John Mello,Dojo 4 Doge,Dallas Seavey,23,Matt Hall,17,Chad Stoddard,18,3:24:00 AM
Chad Throp,Eskimo Pie,Aaron Burmeister,36,Kristy Berington,4,Chad Stoddard,18,5:30:00 AM
Liz Lounsbury,Liz Let The Dogs Out,Dallas Seavey,23,Michelle Phillips,26,Christopher Parker,19,3:53:00 AM
Rachel Hampton,Mushed Potatoes ,Nicolas Petit,10,Matt Hall,17,Will Troshynski,27,11:24:00 AM
Ellen Evans,On By 6 Feet to #1,Brent Sass,21,Dan Kaduce,40,Jeremy Traska,5,11:16:00 AM
Christian Nash,Pitter Patter,Aliy Zirkle,32,Ryan Redington,20,Joanna Jagow,22,11:23:00 AM
Nichole Fogle,Muk & Luk,Aliy Zirkle,32,Rick Casillo,45,Jeremy Traska,5,5:42:00 AM
Laurie Gray,"Don't know ""pupkis"" about sled doggin'",Aliy Zirkle,32,Anna Berington,13,Will Troshynski,27,4:37:00 AM
Jennifer Nichols,Mush Ado About Ruffin',Nicolas Petit,10,Ryan Redington,20,Will Troshynski,27,5:22:00 AM
Amy Atteberry,Get Ready for Mush Hour!,Paige Drobny,42,Gunnar Johnson,11,Jeremy Traska,5,8:42:00 PM
Dan Okray,Someday Came,Dallas Seavey,23,Wade Marrs,25,Christopher Parker,19,8:14:00 AM
Nathan Whitmire,Damn Good Dawgs,Joar Leifseth Ulsom,41,Victoria Hardwick,48,Will Troshynski,27,8:50:00 AM
Hamilton Evans,"Clear Eyes, Masked Up, Can't Lose",Paige Drobny,42,Kristy Berington,4,Chad Stoddard,18,4:15:00 AM
Carrie Flaspohler,Paw Patrol: Everest's Revenge,Travis Beals,33,Cody Strathe,37,Susannah Tuminelli,31,5:13:00 AM
Steve Hinman,Go Dog Go,Aaron Burmeister,36,Anna Berington,13,Sean Underwood,47,5:00:00 AM
Rich Coutee,Heinold's Hound Handler,Jessie Royer,24,Victoria Hardwick,48,Hal Hanson,12,2:06:00 PM
Becky Leach,Balto or Bust ,Peter Kaiser,3,Riley Dyche,16,Jeremy Traska,5,11:20:00 PM
Matt Payne,Mr. Kansas Law Dogs,Nicolas Petit,10,Richie Diehl,6,Will Troshynski,27,7:20:00 AM
Lexie Kubisiak,Lexie's Paw Patrol,Peter Kaiser,3,Kristy Berington,4,Christopher Parker,19,6:30:00 PM
Luxi Williams,He's Just A Little Husky,Brent Sass,21,Ryan Redington,20,Susannah Tuminelli,31,12:00:00 AM
Adrian So,Musher's Paradise,Dallas Seavey,23,Anna Berington,13,Will Troshynski,27,2:37:00 AM
Kate Comstock ,"Feelin’ Husky Nome, Nomed thru 2020 ",Aaron Burmeister,36,Ramey Smyth,9,Chad Stoddard,18,10:00:00 AM
Adam Sheinkopf ,Mushtask you a question ,Peter Kaiser,3,Cody Strathe,37,Hal Hanson,12,4:25:00 AM
Paul Schur,Hush Puppies and The Biscuit,Aaron Burmeister,36,Martin Buser,34,Brenda Mackey,15,1:15:00 AM
Michelle Page-Rivera,Mush ado about nothing,Aaron Burmeister,36,Jeff Deeter,7,Brenda Mackey,15,3:45:00 AM
Elizabeth Otwell ,Do Not Eat the Yellow Snow,Aliy Zirkle,32,Richie Diehl,6,Chad Stoddard,18,2:14:00 AM
Rob Collier,"Not Fat, Just Husky",Peter Kaiser,3,Ryne Olson,14,Chad Stoddard,18,9:42:00 AM
Ben Alfaro,Togo Express,Joar Leifseth Ulsom,41,Kristy Berington,4,Chad Stoddard,18,12:45:00 AM
Joel Levinson,MushItRealGoodDunDunDunDunDun,Nicolas Petit,10,Jeff Deeter,7,Joanna Jagow,22,10:17:00 AM
Mark Fore,Ice Ice Babies,Travis Beals,33,Cindy Gallea,46,Will Troshynski,27,11:30:00 AM
Kim Russell,Pack of Frozen Paws,Dallas Seavey,23,Riley Dyche,16,Joshua McNeal,30,4:40:00 AM
Pierre Russell,White Fang,Travis Beals,33,Rick Casillo,45,Jeremy Traska,5,10:10:00 AM
Amy Roberts,Distant Place,Peter Kaiser,3,Aaron Peck,2,Will Troshynski,27,9:12:00 AM
Todd Bolsen,Hundo Pees,Peter Kaiser,3,Cindy Gallea,46,Hal Hanson,12,1:00:00 PM
Benjamin Mokotoff,"Muffins, Breakfast Breads, etc.",Aaron Burmeister,36,Kristy Berington,4,Will Troshynski,27,7:45:00 AM
Lily Li,Pawsitivity,Nicolas Petit,10,Riley Dyche,16,Chad Stoddard,18,11:32:00 AM
Cindy Mokotoff ,Unlimited Candy Beans,Nicolas Petit,10,Mille Porsild,28,Brenda Mackey,15,6:00:00 PM
Elizabeth Abad,Dont Turn Around,Jessie Royer,24,Michelle Phillips,26,Jeremy Traska,5,11:48:00 PM
Jelena Fordella,Jelena's No Ole Yellers Here,Jessie Royer,24,Ryne Olson,14,Sean Underwood,47,11:11:00 AM
Elisa Warren,Nome Run,Travis Beals,33,Matt Hall,17,Jeremy Traska,5,2:32:00 PM
Dani Charles,COVIDarod,Jessie Royer,24,Michelle Phillips,26,Will Troshynski,27,3:25:00 PM
Kymberli Roberts,Pawbable Claws ,Aaron Burmeister,36,Ryan Redington,20,Hal Hanson,12,1:12:00 AM
Bill Aaron,Too.Mush.Fun,Dallas Seavey,23,Anna Berington,13,Joanna Jagow,22,10:00:00 AM
Pedro Soto,Mushed Potatoes,Peter Kaiser,3,Kristy Berington,4,Joanna Jagow,22,3:22:00 AM
Nicole Schaeffer,The Audacious Mushdash,Travis Beals,33,Cindy Gallea,46,Will Troshynski,27,5:42:00 AM
Brent Carney,Captain Furpants,Travis Beals,33,Richie Diehl,6,Joshua McNeal,30,5:25:00 AM
Mara Barack,Pandemic puppies are the best ,Nicolas Petit,10,Kristy Berington,4,Susannah Tuminelli,31,11:17:00 AM
Marcus Bearden,Canned Heet,Jessie Royer,24,Victoria Hardwick,48,Hal Hanson,12,6:12:00 AM
Carl Newton,Bark Life by Blur,Aaron Burmeister,36,Kristy Berington,4,Will Troshynski,27,4:17:00 AM
Greg Teitelbaum,14 Dog Years To Flatten The Curve,Aaron Burmeister,36,Cindy Gallea,46,Brenda Mackey,15,3:07:00 AM
Robert Doan,Texas Mayhem Mushers,Joar Leifseth Ulsom,41,Matt Hall,17,Chad Stoddard,18,5:36:00 AM
Handly Cameron,Togo Was Robbed,Paige Drobny,42,Kristy Berington,4,Sean Underwood,47,2:15:00 AM
Bethany Riddle,Hussy with a Husky,Nicolas Petit,10,Anna Berington,13,Chad Stoddard,18,4:30:00 PM
James Clark,Doggy Style,Travis Beals,33,Michelle Phillips,26,Chad Stoddard,18,9:17:00 AM
Ashley Parker,DOGonIT!,Travis Beals,33,Richie Diehl,6,Dakota Schlosser,39,10:00:00 AM
Laura Beystehner,LB's Last Frontier,Paige Drobny,42,Cody Strathe,37,Christopher Parker,19,2:36:00 AM
Sam and Barry ,Beethoven ,Brent Sass,21,Dan Kaduce,40,Jeremy Traska,5,2:34:00 AM`;


export default {
    "2020": raw2020,
    "2021": raw2021,
    "2022": raw2022,
    "2023": raw2023,
    "2024": raw2024,
};