import React, { useCallback, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Header from './Header';
import Standings from './Standings';
import Leaderboard from './Leaderboard';
import CheckpointSnapshot from './CheckpointSnapshot';
import selectionsPromise from '../selections';
import { subscribeToStandings } from '../database';
import { StandingsContext, LeaderboardContext } from '../context';
import keyBy from 'lodash/fp/keyBy';
import orderBy from 'lodash/fp/orderBy';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';

const keyByBib = keyBy('bib');
const keyByName = keyBy('name');
const orderByScore = orderBy(['score'], ['asc']);
const mapWithIndex = map.convert({cap: false});

function addRank(row, index) {
    return Object.assign({
        rank : index + 1
    }, row);
 }
 
 const defaultMusher = { pos: 100, musher: 'Musher has not started yet'};
 
 function leaderboardRow(standingsByBib) {
    return (row) => {
        const mushers = row.bibs
            .map((bibNum, index) => (standingsByBib[bibNum] || Object.assign({}, defaultMusher, { musher: row.mushFromSelections[index] + ' [Not Started]'})))
            .map((musher, i) => ({ ...musher, key: i}));

        const allScratched = mushers.every(musher => musher.scratched);
        const baseScore = mushers.map(musher => musher.pos).reduce((a,b) => a + b);

        const score = allScratched ? 400 + baseScore : baseScore;

        return Object.assign({}, row, { mushers, score, allScratched});
    }
 }

function Main(){
    const [selections, setSelections] = useState(undefined);

    const [standings, setStandings] = useState(undefined);
    const [newStandings, setNewStandings] = useState(undefined);

    const [newLeaderboard, setNewLeaderboard] = useState(undefined);
    const [leaderboard, setLeaderboard] = useState(undefined);

    const buildLeaderboard = useCallback((standings) => {
        if(!selections || !standings) return;
        const standingsByBib = keyByBib(standings);
        
        return flow(
            map(leaderboardRow(standingsByBib)),
            orderByScore,
            mapWithIndex(addRank)
        )(selections);
    }, [selections]);


    useEffect(() => {
        selectionsPromise.then(setSelections);
    }, []);

    useEffect(() => {
        return subscribeToStandings(data => setNewStandings(data || []), (e) => {
            console.error('issue subscribing to standings', e);
            setNewStandings([]);
        });
    }, []);

    useEffect(() => {
        if(standings === newStandings) return;

        const oldStandingsByBib = standings ? keyByBib(standings) : {};
        newStandings.forEach(entry => {
            entry.oldData = oldStandingsByBib[entry.bib.toString()];
        });
        setStandings(newStandings);
    }, [standings, newStandings]);

    useEffect(() => {
        if(!selections || !standings) return;
        
        setNewLeaderboard(buildLeaderboard(standings));
        
    }, [selections, standings, buildLeaderboard]);

    useEffect(() => {
        if(leaderboard === newLeaderboard) return;
        const oldLeaderboardByName = keyByName(leaderboard);
        newLeaderboard.forEach(row => row.oldData = oldLeaderboardByName[row.name]);
        setLeaderboard(newLeaderboard);
    }, [ newLeaderboard, leaderboard ]);

    return (
        <StandingsContext.Provider value={standings}>
            <LeaderboardContext.Provider value={{leaderboard, buildLeaderboard}}>
                <Router>
                    <div style={{minWidth: '650px'}}>
                        <Header />
                        <Switch>
                            <Route path="/standings">
                                <Standings />
                            </Route>
                            <Route path="/checkpoint/:checkpoint">
                                <CheckpointSnapshot />
                            </Route>
                            <Route path="/">
                                <Leaderboard />
                            </Route>
                        </Switch>
                    </div>
                </Router>
            </LeaderboardContext.Provider>
        </StandingsContext.Provider>
    )
}

export default Main;