import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useLeaderboard } from '../context';
import { getCheckpointSnapshotDocument } from '../database';
import Leaderboard from './Leaderboard';

function CheckpointSnapshot () {
  const { buildLeaderboard } = useLeaderboard();
  const { checkpoint } = useParams();
  const [loading, setLoading] = useState(true);
  const [checkpointLeaderboard, setCheckpointLeaderboard] = useState(null);
  useEffect(() => {
    getCheckpointSnapshotDocument(checkpoint)
      .then((doc) => {
        if(!doc.exists()){
          setLoading(false);
          return;
        }
        const standings = doc.data().data;
        const leaderboard = buildLeaderboard(standings);
        setCheckpointLeaderboard(leaderboard)
        setLoading(false);
      });
  }, [checkpoint, buildLeaderboard])

  if(loading) return <p>Loading</p>
  if(checkpointLeaderboard === null) return (<p>
    No snapshot found for {checkpoint}. <Link to="/">Return to Main Leaderboard.</Link>
  </p>);
  return <Leaderboard leaderboardOverride={checkpointLeaderboard} />
}

export default CheckpointSnapshot;