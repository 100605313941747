export default `Kelly Tabler,I like big mutts and I cannot lie,Richie Diehl,15,Aaron Peck,31,Jason Mackey,20,Gerhardt Thiart,22,5:28:00 PM
Chad Orwig,Lance You Legend,Wade Marrs,28,Dan Kaduce,11,Kristy Berington,6,"Eddie Burke, Jr.",18,7:53:00 PM
Chris Cobb,Dawg Gone It!,Peter Kaiser,33,Dan Kaduce,11,Deke Naaktgeboren,34,Gregg Vitello,29,3:21:00 AM
Elizabeth Otwell,Do Not Eat the Yellow Snow,Richie Diehl,15,Dan Kaduce,11,Christian Turner,30,Bridgett Watkins,19,2:07:00 AM
Adam Sheinkopf,Mushy snowpants,Peter Kaiser,33,Aaron Peck,31,Riley Dyche,21,KattiJo Deeter,10,4:12:00 AM
Laurie Gray,The Pupsqueaks,Wade Marrs,28,Matt Hall,7,Kristy Berington,6,Jed Stephensen,27,3:27:00 AM
Rick DeMedeiros,Totally Not a Chinese Spy Balloon,Jessie Royer,26,Nicolas Petit,9,Joanna Jagow,25,Jennifer LaBar,13,2:00:00 AM
Gil Elliot,Team Eric,Ryan Redington,5,Dan Kaduce,11,Kelly Maixner,23,Jed Stephensen,27,2:15:00 AM
Dassa,Going Dutch,Jessie Holmes,2,Dan Kaduce,11,Riley Dyche,21,Bailey Vitello,3,6:38:00 AM
Barb Reilly,Snowtally Awesome,Jessie Royer,26,Mille Porsild,4,Kelly Maixner,23,Bailey Vitello,3,12:44:00 PM
Jelena Fordella,Floss Before You Mush,Richie Diehl,15,Mille Porsild,4,Jason Mackey,20,KattiJo Deeter,10,8:37:00 AM
Todd Bolsen,Ruff around the edges,Peter Kaiser,33,Nicolas Petit,9,Jason Mackey,20,Jennifer LaBar,13,2:30:00 PM
Stephanie Strada Smith,The Hungry Huskies,Ryan Redington,5,Matt Hall,7,Jason Mackey,20,Gregg Vitello,29,4:45:00 AM
David Mokotoff,Team Red Whitey and Blue,Peter Kaiser,33,Aaron Peck,31,Jason Mackey,20,Jed Stephensen,27,6:28:00 AM
Douglas Borenstein,Doggie Fresh,Peter Kaiser,33,Dan Kaduce,11,Kelly Maixner,23,Hunter Keefe,12,1:11:00 PM
Jennifer Nichols,"Blue Eyes, Full Coats, Can't Lose",Brent Sass,14,Dan Kaduce,11,Christian Turner,30,"Eddie Burke, Jr.",18,5:22:00 AM
Justin Seides,Hush your mush,Jessie Royer,26,Nicolas Petit,9,Matthew Failor,16,"Eddie Burke, Jr.",18,4:37:00 AM
David Vanderpoel,Slip-Sliding Aroo,Peter Kaiser,33,Matt Hall,7,Joanna Jagow,25,Bailey Vitello,3,5:55:00 AM
Carrie Flaspohler,"It's me, hi, I'm the musher it's me",Richie Diehl,15,Dan Kaduce,11,Kelly Maixner,23,"Eddie Burke, Jr.",18,4:17:00 AM
Nichole Fogle,Mellow Mushers,Ryan Redington,5,Mille Porsild,4,Kristy Berington,6,"Eddie Burke, Jr.",18,4:19:00 AM
Lily Li,The Pupsicles,Ryan Redington,5,Dan Kaduce,11,Riley Dyche,21,Hunter Keefe,12,2:12:00 AM
Rowdy Boling,Iditarod Stewart,Brent Sass,14,Matt Hall,7,Kristy Berington,6,Bailey Vitello,3,3:46:00 AM
Alex Qatsha,Bark de Triomphe,Brent Sass,14,Dan Kaduce,11,"Michael Williams, Jr.",32,Gerhardt Thiart,22,5:40:00 AM
Ellen Evans,Sled Lasso,Peter Kaiser,33,Aaron Peck,31,"Michael Williams, Jr.",32,KattiJo Deeter,10,9:18:00 PM
Bill Aaron,"Please, Help Me Find a Nome",Jessie Royer,26,Nicolas Petit,9,Joanna Jagow,25,Bailey Vitello,3,12:01:00 AM
Stephen Dixon,The Last of Mush,Peter Kaiser,33,Dan Kaduce,11,Joanna Jagow,25,Bridgett Watkins,19,11:15:00 AM
Michael McLaughlin,Thicc Woofers,Richie Diehl,15,Matt Hall,7,Riley Dyche,21,Hunter Keefe,12,3:23:00 AM
Ashley Vanderpoel,Real Dogs wear Booties,Wade Marrs,28,Matt Hall,7,"Michael Williams, Jr.",32,"Eddie Burke, Jr.",18,4:30:00 PM
Jason Franklin,The Fast and the Furriest,Brent Sass,14,Aaron Peck,31,Riley Dyche,21,Bailey Vitello,3,8:48:00 AM
Zach Tribble,Balto III: Wings of Change,Ryan Redington,5,Dan Kaduce,11,Eric Kelly,17,"Eddie Burke, Jr.",18,4:27:00 AM
Alison Lowry,The Frosty Furballs,Jessie Holmes,2,Matt Hall,7,Anna Berington,8,"Eddie Burke, Jr.",18,8:00:00 PM
Robin Orwig,"Mushington, Mushington Sixteen Pups Pull a Sled That Weighs a Heckin Ton",Ryan Redington,5,Nicolas Petit,9,Kelly Maixner,23,Jennifer LaBar,13,2:00:00 PM
Rebecca Leach,"Oh My God Becky, Look at That Mush",Jessie Holmes,2,Matt Hall,7,"Michael Williams, Jr.",32,Jennifer LaBar,13,12:12:00 PM
Chad Thropp,Red Lantern,Brent Sass,14,Mille Porsild,4,Eric Kelly,17,"Eddie Burke, Jr.",18,7:17:00 AM
Aaron Berk,North to the Future,Jessie Holmes,2,Mille Porsild,4,Eric Kelly,17,Bailey Vitello,3,10:23:00 PM
Beth Fore,I'm Gonna Win This Doggone Race!,Jessie Holmes,2,Matt Hall,7,Deke Naaktgeboren,34,Bailey Vitello,3,7:02:00 AM
Tara Montano,Mush Ask You a Question,Wade Marrs,28,Matt Hall,7,Kristy Berington,6,"Eddie Burke, Jr.",18,11:22:00 AM
Chris Maycock,Yuk-raine Avengers,Peter Kaiser,33,Dan Kaduce,11,Riley Dyche,21,Jed Stephensen,27,7:17:00 AM
Jim Fancher,Dogg Father,Brent Sass,14,Dan Kaduce,11,Christian Turner,30,Jennifer LaBar,13,4:28:00 PM
Grant Guthrie,Mush Hour,Wade Marrs,28,Dan Kaduce,11,Jason Mackey,20,Jed Stephensen,27,1:30:00 AM
Matt Payne,House of Blueys,Wade Marrs,28,Aaron Peck,31,Kristy Berington,6,Bailey Vitello,3,1:11:00 PM
Anthony Luaders,Pawful Team Name,Peter Kaiser,33,Nicolas Petit,9,Kelly Maixner,23,Bridgett Watkins,19,6:12:00 PM
Alex Consunji,The movie should have been called Togo,Wade Marrs,28,Aaron Peck,31,Riley Dyche,21,"Eddie Burke, Jr.",18,2:13:00 PM
Hamilton Evans,Mush Ado About Snowthin',Wade Marrs,28,Aaron Peck,31,Matthew Failor,16,Bailey Vitello,3,7:45:00 AM
Geoff Holland,A Comb and a Brush and a Bowl Full of Mush,Ryan Redington,5,Nicolas Petit,9,Kristy Berington,6,KattiJo Deeter,10,6:17:00 AM
Brian O’Connor,Edward’s Snow Den,Peter Kaiser,33,Nicolas Petit,9,Matthew Failor,16,Jennifer LaBar,13,11:37:00 AM
Dani Charles,Mellow Mushers,Ryan Redington,5,Aaron Peck,31,Matthew Failor,16,Bridgett Watkins,19,5:01:00 PM
Thomas Stubbs,Awesome Ready Sleldawgs,Ryan Redington,5,Dan Kaduce,11,Kelly Maixner,23,KattiJo Deeter,10,4:20:00 AM
Liz Lounsbury,Mush Madness,Peter Kaiser,33,Aaron Peck,31,Kelly Maixner,23,Jed Stephensen,27,1:43:00 PM
Rachel Lieberman,Easier Sled than Done,Brent Sass,14,Dan Kaduce,11,Eric Kelly,17,Hunter Keefe,12,2:22:00 AM
Rich Clum,Muttley Crew,Brent Sass,14,Matt Hall,7,Eric Kelly,17,Hunter Keefe,12,4:32:00 AM
Kim Russell,Frostbitten Huskies,Peter Kaiser,33,Aaron Peck,31,Eric Kelly,17,KattiJo Deeter,10,5:35:00 AM
Matt Loutzenhiser,No Squirrels Allowed,Jessie Royer,26,Aaron Peck,31,Christian Turner,30,Jed Stephensen,27,4:15:00 AM
Rob Collier,Anything is Paw-sible,Peter Kaiser,33,Dan Kaduce,11,Christian Turner,30,Bailey Vitello,3,11:52:00 AM
Andrea Goodman,I Promise I'm Returning,Brent Sass,14,Aaron Peck,31,Kristy Berington,6,Gerhardt Thiart,22,10:15:00 AM
Kirsten Hartley,Woofgang Puck,Peter Kaiser,33,Dan Kaduce,11,Riley Dyche,21,Jennifer LaBar,13,4:32:00 AM
Sandeep Alexander Goni,Mystic Dragonfly,Brent Sass,14,Aaron Peck,31,"Michael Williams, Jr.",32,Hunter Keefe,12,7:00:00 PM
Pierre Russell,Snow Scooter,Peter Kaiser,33,Matt Hall,7,"Michael Williams, Jr.",32,Bridgett Watkins,19,2:00:00 PM
Kymberli Tate,"Mush, Mush, Mush to my Lou",Peter Kaiser,33,Matt Hall,7,Anna Berington,8,Gregg Vitello,29,4:38:00 AM
Joel levinson,Mush it. Mush it real good (dundundundundun),Wade Marrs,28,Nicolas Petit,9,Matthew Failor,16,Gregg Vitello,29,8:38:00 AM
John Mello,"Ate Kibble, Left No Crumbs",Ryan Redington,5,Matt Hall,7,Christian Turner,30,Gregg Vitello,29,4:13:00 AM
M. Hans Delly,Loup-Garou,Wade Marrs,28,Aaron Peck,31,Deke Naaktgeboren,34,Gerhardt Thiart,22,3:07:00 AM
Nathan Whitmire,The Chilly Taco Bell Chihuahuas,Ryan Redington,5,Aaron Peck,31,Kelly Maixner,23,Jed Stephensen,27,3:33:00 AM
Melissa DeMedeiros,Ready Set Dog!,Richie Diehl,15,Aaron Peck,31,Jason Mackey,20,Gerhardt Thiart,22,3:30:00 AM
Laura Bolsen,Jim Rockford...leave a message,Richie Diehl,15,Dan Kaduce,11,Kristy Berington,6,Bailey Vitello,3,4:13:00 AM
Rachel McLaughlin,There’s no place like Nome,Richie Diehl,15,Dan Kaduce,11,Christian Turner,30,Jennifer LaBar,13,3:30:00 PM
Allie Moberg,MushMush,Jessie Royer,26,Nicolas Petit,9,Christian Turner,30,Bailey Vitello,3,1:15:00 PM
Jane Guthrie,The Fast and The Furriest,Peter Kaiser,33,Aaron Peck,31,"Michael Williams, Jr.",32,Jennifer LaBar,13,1:30:00 AM
Mike Marshall,ET phone Nome,Ryan Redington,5,Dan Kaduce,11,Kelly Maixner,23,Jed Stephensen,27,11:37:00 AM
Marty Young,Team Red Lantern,Brent Sass,14,Dan Kaduce,11,Kelly Maixner,23,"Eddie Burke, Jr.",18,5:55:00 AM
Brian and Mina,Lion of Lyons,Richie Diehl,15,Matt Hall,7,Matthew Failor,16,Bailey Vitello,3,12:12:00 AM
Amy Atteberry,Sledding Crashers,Ryan Redington,5,Nicolas Petit,9,Riley Dyche,21,Jed Stephensen,27,7:15:00 PM
Pedro J. Soto,Mushed Potatoes & Rabies,Richie Diehl,15,Aaron Peck,31,Kelly Maixner,23,"Eddie Burke, Jr.",18,3:25:00 AM
Handly Cameron,Jolly Holiday,Ryan Redington,5,Aaron Peck,31,Matthew Failor,16,Hunter Keefe,12,10:46:00 AM
Paige Sento,"Bacon, Bacon, Bacon",Peter Kaiser,33,Dan Kaduce,11,Anna Berington,8,Bridgett Watkins,19,6:00:00 AM
Rob Eble,Nome Salad,Wade Marrs,28,Mille Porsild,4,Christian Turner,30,Jennifer LaBar,13,10:30:00 AM
Michelle Page,Runt of the Iditar,Ryan Redington,5,Matt Hall,7,Kristy Berington,6,Gregg Vitello,29,5:15:00 AM
Mahdi Albori,Fast & Furriest,Ryan Redington,5,Matt Hall,7,Riley Dyche,21,Gregg Vitello,29,9:00:00 AM
Ben Alfaro,Ice Road Mushers,Jessie Holmes,2,Nicolas Petit,9,Kelly Maixner,23,Bailey Vitello,3,9:43:00 AM
Ronak Pattani,Fiddler on the Woof,Wade Marrs,28,Mille Porsild,4,Kelly Maixner,23,Hunter Keefe,12,11:00:00 AM
Alan Shearer,Hitting Paws,Peter Kaiser,33,Aaron Peck,31,"Michael Williams, Jr.",32,Gerhardt Thiart,22,5:42:00 PM
Christian Nash,Chinese Spy Sleds,Peter Kaiser,33,Matt Hall,7,Matthew Failor,16,Jed Stephensen,27,10:47:00 AM
Shaun McCarthy,Too Mush Information,Peter Kaiser,33,Dan Kaduce,11,Kelly Maixner,23,Bailey Vitello,3,2:15:00 PM
Mara Barack,The Cutting Edge,Richie Diehl,15,Matt Hall,7,Deke Naaktgeboren,34,Jennifer LaBar,13,9:53:00 AM
Elisa Warren,Formula 1 Drooling,Wade Marrs,28,Matt Hall,7,Christian Turner,30,Jennifer LaBar,13,5:47:00 AM
Shaun Bank,Double Dawgs,Ryan Redington,5,Aaron Peck,31,Kelly Maixner,23,Bailey Vitello,3,9:11:00 AM
Bethany Riddle,Mush Ado About Nothing,Richie Diehl,15,Dan Kaduce,11,Kelly Maixner,23,Bailey Vitello,3,6:22:00 AM
Craig Marmon,Squirrel Scanners,Jessie Royer,26,Aaron Peck,31,Joanna Jagow,25,Jennifer LaBar,13,10:02:00 AM
Justin Tennant,Woodstock,Jessie Holmes,2,Matt Hall,7,Christian Turner,30,Jed Stephensen,27,7:58:00 AM
Helena Chern,Togo the real MVP,Jessie Holmes,2,Matt Hall,7,Kelly Maixner,23,Bailey Vitello,3,2:22:00 PM
Todd Jackson,Only 34,Jessie Royer,26,Nicolas Petit,9,Anna Berington,8,Bailey Vitello,3,3:52:00 AM
Davis Palmie,Talladega Pups,Jessie Royer,26,Aaron Peck,31,Jason Mackey,20,Bridgett Watkins,19,3:45:00 PM
Dan Okray,Someday Came,Wade Marrs,28,Dan Kaduce,11,Riley Dyche,21,"Eddie Burke, Jr.",18,5:14:00 AM
Joanne Mello,With Great Powder Comes Great Responsibility,Peter Kaiser,33,Dan Kaduce,11,Riley Dyche,21,Hunter Keefe,12,5:35:00 AM
Jake Martin,Martin's Malamutes,Ryan Redington,5,Nicolas Petit,9,Kelly Maixner,23,"Eddie Burke, Jr.",18,5:36:00 PM
Carl Newton,The Dog’s Dinner,Wade Marrs,28,Nicolas Petit,9,Kelly Maixner,23,Bridgett Watkins,19,8:37:00 AM
Tim Bausman,Are we there yet?,Jessie Royer,26,Nicolas Petit,9,Joanna Jagow,25,Jed Stephensen,27,5:43:00 AM
Rich Coutee,Balto's Ghost,Wade Marrs,28,Nicolas Petit,9,Joanna Jagow,25,Jed Stephensen,27,2:16:00 PM
Lee Jaffe,Ice Pups,Wade Marrs,28,Nicolas Petit,9,Christian Turner,30,Jennifer LaBar,13,10:00:00 AM
Laura Beystehner,Deadliest Dogs,Wade Marrs,28,Nicolas Petit,9,Riley Dyche,21,Jed Stephensen,27,5:39:00 AM
Ken Simpson,"Sorry, I was on malamute",Jessie Holmes,2,Aaron Peck,31,Joanna Jagow,25,Gregg Vitello,29,3:21:00 PM
Mark Fore,Greatest American Heroes,Jessie Holmes,2,Matt Hall,7,Deke Naaktgeboren,34,Jennifer LaBar,13,11:30:00 AM
Jarrod Carpenter,Gee Haw Heet I've Got Happy Feet,Jessie Holmes,2,Dan Kaduce,11,Deke Naaktgeboren,34,Hunter Keefe,12,5:33:00 AM
Christopher Lieberman,Chasing Tails,Jessie Holmes,2,Dan Kaduce,11,Deke Naaktgeboren,34,Jennifer LaBar,13,2:49:00 AM
Walt Austin,The Ice Sled Cometh,Peter Kaiser,33,Nicolas Petit,9,Jason Mackey,20,Hunter Keefe,12,12:40:00 AM
Jeremy Lizza,Spy Dogs,Wade Marrs,28,Aaron Peck,31,Riley Dyche,21,"Eddie Burke, Jr.",18,11:15:00 PM
Joey Ruse,Snow Way,Ryan Redington,5,Aaron Peck,31,Deke Naaktgeboren,34,"Eddie Burke, Jr.",18,2:05:00 PM
ELIZABETH ABAD,Trypophobia,Peter Kaiser,33,Matt Hall,7,Riley Dyche,21,Gerhardt Thiart,22,1:36:00 AM
Kim Morris,Super Poopers,Brent Sass,14,Dan Kaduce,11,Kristy Berington,6,Gerhardt Thiart,22,1:47:00 AM
Steven Carter,Mush Ado About Nothing,Jessie Holmes,2,Aaron Peck,31,Eric Kelly,17,"Eddie Burke, Jr.",18,7:13:00 AM
Matt Matuszewski,Murphy's Law,Peter Kaiser,33,Dan Kaduce,11,Jason Mackey,20,Jed Stephensen,27,4:25:00 AM
Hailey Judge,doggie sledders,Ryan Redington,5,Dan Kaduce,11,"Michael Williams, Jr.",32,Bailey Vitello,3,6:47:00 PM
Stephen Hinman,Snoop’s Doggs,Peter Kaiser,33,Dan Kaduce,11,Kelly Maixner,23,Gregg Vitello,29,4:00:00 AM
Marcus Bearden,The Fast and the Furrys,Ryan Redington,5,Dan Kaduce,11,Joanna Jagow,25,Hunter Keefe,12,2:23:00 AM
Susan Ccarce,Snow Doggy Dogg,Wade Marrs,28,Nicolas Petit,9,Christian Turner,30,Jennifer LaBar,13,4:23:00 AM
Christian Wayne,The Last of Hus(kies),Jessie Holmes,2,Nicolas Petit,9,Kristy Berington,6,Jennifer LaBar,13,3:00:00 PM
Brent Carney,Captain Furpants,Jessie Holmes,2,Nicolas Petit,9,Jason Mackey,20,Jennifer LaBar,13,1:23:00 PM
Carey Johnson,Don’t tell me you forgot the serum,Peter Kaiser,33,Matt Hall,7,Kelly Maixner,23,Jed Stephensen,27,2:36:00 AM
Gabby John,Team Olaf,Ryan Redington,5,Mille Porsild,4,Joanna Jagow,25,Gerhardt Thiart,22,6:39:00 PM
Bridget Graf,In my mush era,Brent Sass,14,Mille Porsild,4,Kristy Berington,6,Bridgett Watkins,19,10:42:00 PM
Robert Doan,Texas Mayhem Mushers,Wade Marrs,28,Dan Kaduce,11,Christian Turner,30,KattiJo Deeter,10,3:40:00 AM
Dave Dresden,Hot Desert-Dogs,Jessie Royer,26,Nicolas Petit,9,Joanna Jagow,25,Gregg Vitello,29,4:07:00 AM
Cindy Mokotoff,Four off the Floor,Richie Diehl,15,Nicolas Petit,9,Kristy Berington,6,"Eddie Burke, Jr.",18,7:00:00 AM
Benjamin Mokotoff,Date Shakes,Ryan Redington,5,Dan Kaduce,11,Eric Kelly,17,Bailey Vitello,3,4:17:00 AM`;